<template>
  <div class="user-layout">
    <router-link to="/" class="logo">
      <img :src="require('@/assets/logo.png')" alt="logo" />
    </router-link>
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UserLayout',
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
.user-layout {
  width: 100%;
  height: 100%;
  background: rgb(245, 247, 250);
  font-size: 18px;
  padding-top: 100px;
  text-align: center;

  .logo {
    display: inline-block;
    margin: 32px auto;
    img {
      width: auto;
      height: 50px;
    }
  }
}
</style>
